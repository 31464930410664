import { BaseResponse, DataStoreBase } from "./base"
import { Note } from "./note";

export interface ContactsResponse extends BaseResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    page: string,
    contacts: Contact[],
}

export interface Contact extends DataStoreBase {
    clientID: string,
    dealID: string,
    firstName: string,
    lastName: string,
    notes: Note[],
    phones: Phone[],
    email: string,
    NameCityHash: string,
}

// Phone represents a phone number and its associated details
export interface Phone {
    number: string,
    type: PhoneType,
    status: PhoneStatus,
    priority: number,
}

// PhoneType is the type of phone number
export enum PhoneType {
    Wireless = "wireless",
    Landline = "landline",
    Unknown = ""
}

// PhoneStatus is the status of the phone number
export enum PhoneStatus {
    Verified = "verified",
    Unverified = "unverified",
    DoNotCall = "do_not_call",
    DoNotText = "do_not_text",
    Unknown = ""
}

export interface ContactUploadList {
    id: string,
    requestedAt: string,
    clientID: string,
    s3Uri: string,
    columnMappings: { [key: string]: number },
    fields: string[],
    recordCount: number,
    info: string,
    originalFilename: string,
    campaignID: string,
}

export interface ContactsUploadResponse extends BaseResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    page: string,
    contactUploadList: ContactUploadList,
}