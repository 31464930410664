import { fetchAuthSession } from "aws-amplify/auth";
import isNetworkError from 'is-network-error';

const Environment: string = getEnvironment()

export function getEnvironment() {
    let env = process.env.REACT_APP__ENV;

    if (env === undefined || env === "") {
        env = "prod"
        console.log("no REACT_APP__ENV environment variable set...defaulting to prod")
    }

    return env
}

// parseURLMeat is a helper function that takes a URL and returns the meat of the URL
// which is used only for logging purposes
function parseURLMeat(url: string): string {
    const idx = url.indexOf(".amazonaws.com")

    if (idx < 0) {
        return url
    }

    return url.substring(idx + 14)
}

export const httpGet = async (apiURL: string) => {
    try {
        console.log('[GET] ' + parseURLMeat(apiURL));

        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }

        const apiResponse = await fetch(apiURL, {
            headers: {
                'authorization': authSession.tokens.accessToken.toString(),
            }
        });
        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            throw apiResponse.statusText
        } else {
            const json = await apiResponse.json();
            console.log('[GET-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
            if (json.status === 'error') {
                console.log('Error is: ' + json.errorMessage);
            }

            return json
        }
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpGetData = async (apiURL: string) => {
    try {
        console.log('[GET-DATA] ' + parseURLMeat(apiURL));

        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }

        const apiResponse = await fetch(apiURL, {
            headers: {
                'authorization': authSession.tokens.accessToken.toString(),
            }
        });

        if (!apiResponse.ok) {
            console.log("apiResponse Error: ", apiResponse.statusText)
            throw apiResponse.statusText
        } else {
            const blob = await apiResponse.blob();
            console.log('Blob: ', blob)
            console.log(blob.size)
            console.log('[GET-DATA-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + blob.size + ' bytes)');
            return blob
        }
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpGetNoAuth = async (apiURL: string) => {
    try {
        console.log('[GET-NOAUTH] ' + parseURLMeat(apiURL));

        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }

        const apiResponse = await fetch(apiURL, {
            headers: {
                'authorization': authSession.tokens.accessToken.toString(),
            }
        });

        const json = await apiResponse.json();
        console.log('[GET-NOAUTH-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status === 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPost = async (apiURL: string, jsonStr: string) => {
    try {
        console.log('[POST] ' + parseURLMeat(apiURL));

        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }

        const apiResponse = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'authorization': authSession.tokens.accessToken.toString(),
            },
            body: jsonStr,
        });

        const json = await apiResponse.json();
        console.log('[POST-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status === 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPostFormData = async (apiURL: string, formData: FormData) => {
    try {
        console.log('[POST] ' + parseURLMeat(apiURL));

        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }

        const apiResponse = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'authorization': authSession.tokens.accessToken.toString(),
            },
            body: formData,
        });

        const json = await apiResponse.json();
        console.log('[POST-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status === 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPut = async (apiURL: string, jsonStr: string) => {
    try {
        console.log('[PUT] ' + parseURLMeat(apiURL));

        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }

        const apiResponse = await fetch(apiURL, {
            method: 'PUT',
            headers: {
                'authorization': authSession.tokens.accessToken.toString(),
            },
            body: jsonStr,
        });

        const json = await apiResponse.json();
        console.log('[PUT-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status === 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpPostNoAuth = async (apiURL: string, jsonStr: string) => {
    try {
        console.log('[POST-NOAUTH] ' + parseURLMeat(apiURL));

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('environment', Environment);
        const apiResponse = await fetch(apiURL, {
            method: 'POST',
            headers: {
                'environment': Environment,
            },
            body: jsonStr,
        });
        const json = await apiResponse.json();
        console.log('[POST-NOAUTH-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status === 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export const httpDelete = async (apiURL: string) => {
    try {
        console.log('[DELETE] ' + parseURLMeat(apiURL));

        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }

        const apiResponse = await fetch(apiURL, {
            method: 'DELETE',
            headers: {
                'authorization': authSession.tokens.accessToken.toString(),
            }
        });

        const json = await apiResponse.json();
        console.log('[DELETE-RESPONSE] ' + parseURLMeat(apiURL) + ' (' + json.status + ')');
        if (json.status === 'error') {
            console.log('Error is: ' + json.errorMessage);
        }

        return json
    } catch (error) {
        let message = "Unknown Error"
        if (error instanceof Error) {
            message = error.message
        }

        if (isNetworkError(error)) {
            throw new Error('Network Error - ' + message)
        } else {
            console.log("Non-Network error: " + message)
        }
    }
}

export enum EndpointType {
    clients = "clients",
    communications = "communications",
    contacts = "contacts",
    deals = "deals",
    pipelines = "pipelines",
    voicescaleunits = "ai/voice/scaleunits",
    voicequeue = "ai/voice/jobs",
    voicescripts = "ai/voice/scripts",
    users = "users",
}

export const APIEndpoint = (endpointType: EndpointType) => {
    return "https://i13d3qq58a.execute-api.us-west-2.amazonaws.com/dev/" + endpointType
}

export function ParseQueryString(queryString: string): Map<string, string> {
    const queryParams = new Map<string, string>();

    // Remove the leading '?' if present
    if (queryString.startsWith('?')) {
        queryString = queryString.slice(1);
    }

    // Split the query string into individual key-value pairs
    const pairs = queryString.split('&');

    // Iterate through each pair and populate the map
    pairs.forEach((pair) => {
        const [key, value] = pair.split('=');

        // Decode the key and value to handle special characters
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value || '');

        queryParams.set(decodedKey, decodedValue);
    });

    return queryParams;
}