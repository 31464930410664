import { BaseResponse, DataStoreBase } from "./base"
import { ClientType } from "./clients";

export interface UsersResponse extends BaseResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    page: string,
    users: User[],
}

export interface User extends DataStoreBase {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    role: UserRole,
    lastAccessedAt: string,
    clients: ClientReference[],
    calendar: CalendarInfo
    AdditionalPermissions: String[],
}

export interface ClientReference {
    clientType: ClientType,
    clientID: string,
}

export enum UserRole {
    // UserRole_Automation is the role for an automation user. This role is used for automated processes and should not be assigned to a human user.
    User_Role_Automation = "automation",
    // UserRole_SuperAdmin is the role for a super administrator. They have access to EVERYTHING. This role should be assigned sparingly.
    UserRole_SuperAdmin = "superAdmin",
    // UserRole_Admin is the role for an administrator. They have access to all facilities across all clients and can manage billing and account settings.
    // They can impersonate any user. They cannot elevate users to the Admin role.
    UserRole_Admin = "admin",
    // UserRole_Client_SuperAdmin is the role for a super administrator of a client. They have access to all facilities for a client and can manage billing and account settings.
    // SuperAdmins have all permissions of everything below it. They can elevate users to the SuperAdmin and Admin roles.
    UserRole_Client_SuperAdmin = "clientSuperAdmin",
    // UserRole_Admin is the role for an administrator of a client. They have access to all facilities across all Clients. They can manage users (add, edit, delete) but cannot elevate users to the SuperAdmin role.
    UserRole_Client_Admin = "clientAdmin",
    // UserRole_SuperUser is the role for a super user of a Client. They can delete, upload and download contacts/deals but do not have access to billing or client settings.
    UserRole_Client_SuperUser = "clientSuperUser",
    // UserRole_Client_User is the role for a user of a Client. They can see only contacts and deals they have created. They can archive contacts and deals they have created but not delete them.
    UserRole_Client = "clientUser",
    // UserRole_Unknown is an Unknown role for a user. This should never be used.
    UserRole_Unknown = ""
}

export interface CalendarInfo {
    // GoogleCalendarID is the ID of the Google Calendar that the user has connected to the system
    GoogleCalendarID: string,
    // CalendlyLink is the link to the Calendly calendar that the user has connected to the system
    CalendlyLink: string,
    // CalendlyTimezone is the timezone of the Calendly calendar that the user has connected to the system
    CalendlyTimezone: string,
    // CalendlyAuthToken is the authentication token for the Calendly calendar that the user has connected to the system
    CalendlyAuthToken: string,
}
