import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Container, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from 'react';
import { useContactsFileUpload } from '../hooks/useContactsFileUpload';
import { APIEndpoint, EndpointType, httpPut } from '../utils/apiService';
import CloseIcon from '@mui/icons-material/Close';
import { ContactsUploadResponse } from '../models/contacts';
import { User } from '../models/users';
import { fetchAuthSession } from '@aws-amplify/auth';

interface Props {
    activeUser: User
    close(): void
}

export default function ContactsUpload(props: Props) {
    const [uploadFormData, setUploadFormData] = React.useState<FormData>(new FormData())
    const [startUpload, setStartUpload] = useState(false)
    const [accessToken, setAccessToken] = useState("")
    const [firstNameMap, setFirstNameMap] = useState("Not Used")
    const [lastNameMap, setLastNameMap] = useState("Not Used")
    const [addressMap, setAddressMap] = useState("Not Used")
    const [cityMap, setCityMap] = useState("Not Used")
    const [stateMap, setStateMap] = useState("Not Used")
    const [zipMap, setZipMap] = useState("Not Used")
    const [wirelessPhone1Map, setWirelessPhone1Map] = useState("Not Used")
    const [wirelessPhone2Map, setWirelessPhone2Map] = useState("Not Used")
    const [landlinePhoneMap, setLandlinePhoneMap] = useState("Not Used")
    const [emailMap, setEmailMap] = useState("Not Used")
    const [warningMessage, setWarningMessage] = useState("")
    const [fileUploadLimitWarning, setFileUploadLimitWarning] = useState("")
    const [clientID, setClientID] = useState("")

    // Hooks
    const { contactUploadCompleted, contactUploadError, contactUploadList, contactUploadProgress} = useContactsFileUpload(uploadFormData, startUpload, clientID, accessToken)
    
    useEffect(() => {
        getAccessToken()
    }, []);

    useEffect(() => {
        if (props.activeUser && props.activeUser.clients && props.activeUser.clients.length > 0) {
            setClientID(props.activeUser.clients[0].clientID);
        }
    }, [props.activeUser]);

    useEffect(() => {
        console.log("Contacts Upload ClientID: " + clientID)
    }, [clientID]);

    useEffect(() => {
        if (contactUploadList) {
            // loop through each of the columnMappings and set default value if found
            if (contactUploadList.columnMappings) {
                for (const [key, value] of Object.entries(contactUploadList.columnMappings)) {
                    if (key === "firstName") {
                        setFirstNameMap(contactUploadList.fields[value])
                    } else if (key === "lastName") {
                        setLastNameMap(contactUploadList.fields[value])
                    } else if (key === "address") {
                        setAddressMap(contactUploadList.fields[value])
                    } else if (key === "city") {
                        setCityMap(contactUploadList.fields[value])
                    } else if (key === "state") {
                        setStateMap(contactUploadList.fields[value])
                    } else if (key === "zip") {
                        setZipMap(contactUploadList.fields[value])
                    } else if (key === "wirelessPhone1") {
                        setWirelessPhone1Map(contactUploadList.fields[value])
                    } else if (key === "wirelessPhone2") {
                        setWirelessPhone2Map(contactUploadList.fields[value])
                    } else if (key === "landlinePhone") {
                        setLandlinePhoneMap(contactUploadList.fields[value])
                    } else if (key === "email") {
                        setEmailMap(contactUploadList.fields[value])
                    }
                }
            }
        }
    }, [contactUploadList]);

    useEffect(() => {
        console.log("lastNameMap: " + lastNameMap)
        console.log("firstNameMap: " + firstNameMap)
        console.log("addressMap: " + addressMap)
        console.log("cityMap: " + cityMap)
        console.log("stateMap: " + stateMap)
        console.log("zipMap: " + zipMap)
        console.log("wirelessPhone1Map: " + wirelessPhone1Map)
        console.log("wirelessPhone2Map: " + wirelessPhone2Map)
        console.log("landlinePhoneMap: " + landlinePhoneMap)
        console.log("emailMap: " + emailMap)

        //getIdxFromFieldName(addressMap)
        //getIdxFromFieldName(zipMap)

    }, [firstNameMap, lastNameMap, addressMap, cityMap, stateMap, zipMap, wirelessPhone1Map, wirelessPhone2Map, landlinePhoneMap, emailMap]);

    const uploadFilenameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileUploadLimitWarning("")
        const files = event.target.files;
        if (files) {
            const file = files[0];

            // Verify the file is less than 5MB
            const size = (file.size);
            console.log("Upload file size: " + size)

            if (size > 4500000) {
                setFileUploadLimitWarning("File is too large! Size must be less than 4.5MB")
                return
            }

            const formData = new FormData();
            formData.append('file', file);
            setUploadFormData(formData);
        }
    }

    const uploadButtonClicked = () => {
        if (uploadFormData.get("file") == null) {
            return
        }
        setStartUpload(true)
    }

    const submitButtonClicked = () => {
        setWarningMessage("")
        // Validate that required fields are mapped
        if (addressMap === "-1") {
            setWarningMessage("The Address field must be mapped to a valid field")
            return
        }

        if (zipMap === "-1") {
            setWarningMessage("The Zip field must be mapped to a valid field")
            return
        }

        const contacts = contactUploadList

        if (contacts) {
            //contacts.status = SuppressionListStatus.ready_for_processing
            contacts.columnMappings = {}
            contacts.columnMappings["address"] = getIdxFromFieldName(addressMap)
            contacts.columnMappings["zip"] = getIdxFromFieldName(zipMap)

            if (firstNameMap !== "Not Used") {
                contacts.columnMappings["firstName"] = getIdxFromFieldName(firstNameMap)
            }

            if (lastNameMap !== "Not Used") {
                contacts.columnMappings["lastName"] = getIdxFromFieldName(lastNameMap)
            }

            if (cityMap !== "Not Used") {
                contacts.columnMappings["city"] = getIdxFromFieldName(cityMap)
            }

            if (stateMap !== "Not Used") {
                contacts.columnMappings["state"] = getIdxFromFieldName(stateMap)
            }

            if (wirelessPhone1Map !== "Not Used") {
                contacts.columnMappings["wirelessPhone1"] = getIdxFromFieldName(wirelessPhone1Map)
            }

            if (wirelessPhone2Map !== "Not Used") {
                contacts.columnMappings["wirelessPhone2"] = getIdxFromFieldName(wirelessPhone2Map)
            }

            if (landlinePhoneMap !== "Not Used") {
                contacts.columnMappings["landlinePhone"] = getIdxFromFieldName(landlinePhoneMap)
            }

            if (emailMap !== "Not Used") {
                contacts.columnMappings["email"] = getIdxFromFieldName(emailMap)
            }

            console.log("Colum Mappings: " + JSON.stringify(contacts.columnMappings))

            try {
                const apiURL = APIEndpoint(EndpointType.contacts) + `/upload/${contacts.id}?client-id=${clientID}`;
                httpPut(apiURL, JSON.stringify(contacts))
                    .then((data) => {
                        const response = data as ContactsUploadResponse;

                        if (response.status === 'error') {
                            setWarningMessage("Error creating transaction! " + response.errorMessage)
                        } else {
                            alert("The file has been submitted for processing. You will receive an email notification within the next several minutes upon its completion.")
                            props.close()
                        }
                    })
                    .catch((error) => {
                        setWarningMessage(error)
                    });
            } catch (error) {
                setWarningMessage("failed to submit contacts list")
            }
        }
    }

    const getIdxFromFieldName = (fieldName: string): number => {
        if (!contactUploadList) {
            return -1
        }

        const idx = contactUploadList.fields.indexOf(fieldName)
        console.log(fieldName, idx)
        return idx
    }

    async function getAccessToken() {
        // Get the access token
        const authSession = await fetchAuthSession();
        if (!authSession || !authSession.tokens) {
            console.log("authSession Error: ", authSession)
            return
        }
        setAccessToken(authSession.tokens.accessToken.toString())
    }

    return (
        <Container maxWidth="xl" disableGutters={true} sx={{}}>
            <Paper >
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Typography variant="h5" sx={{ ml: 1 }}>Contacts List Upload</Typography>
                    <IconButton sx={{ marginTop: -.5 }} size="small" type="button" onClick={() => props.close()} >
                        <CloseIcon />
                    </IconButton>
                </Stack>

                {!contactUploadCompleted &&
                    <Box>
                        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" spacing={1} sx={{ ml: 1 }}>
                            <Typography variant="body1" sx={{  }}>Submit a contacts file to import contacts and start a campaign.</Typography>
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>File Requirements:</Typography>
                            <Typography variant="body1" sx={{ pl: 2 }}>&bull; Supported file types: .csv, .xls, .xlsx</Typography>
                            <Typography variant="body1" sx={{ pl: 2 }}>&bull; Minimum required fields are First Name, Last Name and Phone</Typography>
                            <Typography variant="body1" sx={{ pl: 2 }}>&bull; Supported fields: First Name, Last Name, Address, City, State, Zip, Email and Phones (wireless and landline)</Typography>
                        </Stack>

                        <FormControl>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2, mb: 1, ml: 1 }}>
                                <TextField type="file" onChange={uploadFilenameChanged} inputProps={{ accept: ".csv,.xls,.xlsx" }} sx={{ width: 648 }} />
                                <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={uploadButtonClicked}>Upload</Button>
                            </Stack>
                            {fileUploadLimitWarning !== "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1, mt:1 }}>{fileUploadLimitWarning}</Alert>}
                        </FormControl>
                    </Box>
                }

                {contactUploadCompleted
                    ? <Grid sx={{ mt: 1, ml: 1, mr: 1 }}>
                        {contactUploadList && contactUploadList.id !== "" && contactUploadError === "" &&
                            <Grid>
                                <Typography variant="h6">Field Mapping:</Typography>
                                <Typography variant="body1">In order to complete the suppression file submission process, fields must be mapped so we know which ones to use. Address and Zip are the only required fields.</Typography>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 2, pb: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>First Name:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setFirstNameMap(e.target.value))} size="small" value={firstNameMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>Last Name:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setLastNameMap(e.target.value))} size="small" value={lastNameMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>Address:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setAddressMap(e.target.value))} size="small" value={addressMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>City:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setCityMap(e.target.value))} size="small" value={cityMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>State:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setStateMap(e.target.value))} size="small" value={stateMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>Zip Code:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setZipMap(e.target.value))} size="small" value={zipMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>Wireless Phone (Primary):</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setWirelessPhone1Map(e.target.value))} size="small" value={wirelessPhone1Map}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>Wireless Phone (Secondary):</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setWirelessPhone2Map(e.target.value))} size="small" value={wirelessPhone2Map}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>Landline Phone:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setLandlinePhoneMap(e.target.value))} size="small" value={landlinePhoneMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ pb: 1, mt: 1 }}>
                                    <Typography variant="body1" sx={{ width: 220 }}>Email:</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Fields</InputLabel>
                                        <Select labelId="transType" label="Fields" onChange={(e => setEmailMap(e.target.value))} size="small" value={emailMap}>
                                            <MenuItem value={"Not Used"} key={-1}>Not Used</MenuItem>
                                            {contactUploadList && contactUploadList.fields.map((fm, index) => (
                                                <MenuItem value={fm} key={index}>{fm}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                {warningMessage !== "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{warningMessage}</Alert>}
                                <Grid container direction="row" alignItems="center" justifyContent="center" sx={{ mt: 1, pb: 1 }}>
                                    <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} onClick={submitButtonClicked}>Complete Submission</Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    : <Grid>
                        {contactUploadProgress > 0 &&
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1 }}>
                                <Typography variant="body1" sx={{ width: 120 }}>Uploading file...</Typography>
                                <Box sx={{ width: '81%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={contactUploadProgress} />
                                </Box>
                            </Stack>
                        }
                    </Grid>
                }

                {contactUploadError && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{contactUploadError}</Alert>}
            </Paper>
        </Container>
    );
}
