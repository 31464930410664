import { useEffect, useState } from 'react';
import { APIEndpoint, EndpointType } from "../utils/apiService";
import axios from 'axios';
import { ContactsUploadResponse, ContactUploadList } from '../models/contacts';

export const useContactsFileUpload = (formData: FormData, startUpload: boolean, clientID: string, accessToken: string) => {
    const [contactUploadProgress, setContactUploadProgress] = useState(0)
    const [contactUploadError, setContactUploadError] = useState("")
    const [contactUploadList, setContactUploadList] = useState<ContactUploadList>()
    const [contactUploadCompleted, setContactUploadCompleted] = useState(false)

    function parseURLMeat(url: string): string {
        const idx = url.indexOf(".amazonaws.com")

        if (idx < 0) {
            return url
        }

        return url.substring(idx + 14)
    }

    useEffect(() => {
        if (!startUpload) {
            return
        }

        setContactUploadCompleted(false)
        setContactUploadProgress(1)
        setContactUploadError("")
        setContactUploadList(undefined)

        try {
            setContactUploadCompleted(false)
            const apiURL = APIEndpoint(EndpointType.contacts) + "/upload?client-id=" + clientID
            console.log('[CONTACTS FILEUPLOAD] ' + parseURLMeat(apiURL));

            axios.post(apiURL, formData, {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    if (total !== undefined) {
                        const percentage = Math.floor((loaded * 100) / total);
                        setContactUploadProgress(percentage);
                    }
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': accessToken,
                }
            }).then((res) => {
                setContactUploadProgress(100);
                const response = res.data as ContactsUploadResponse

                if (response.status === "error") {
                    setContactUploadError(response.errorMessage)
                } else {
                    setContactUploadList(response.contactUploadList)
                }

            }).catch((error) => {
                setContactUploadError("failed to upload file - " + error)
                setContactUploadCompleted(true)
            }).finally(() => {
                setContactUploadCompleted(true);
            });
        } catch (error) {
            setContactUploadError("failed to upload file")
            setContactUploadCompleted(true)
        }
    }, [accessToken, formData, startUpload, clientID]);

    return { contactUploadProgress, contactUploadError, contactUploadList, contactUploadCompleted };
}