import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { VoiceScript, VoiceScriptsResponse } from '../models/scripts';

const apiURL =  APIEndpoint(EndpointType.voicescripts);

export const useGetVoiceScripts = (clientID: string, page: string, refreshToken: number) => {
    const [voiceScripts, setVoiceScripts] = useState<VoiceScript[]>()
    const [voiceScriptsPage, setVoiceScriptsPage] = useState("")
    const [voiceScriptsError, setVoiceScriptsError] = useState("")
    const [voiceScriptsLoading, setVoiceScriptsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;
        if (clientID === "") return;

        setVoiceScripts([])
        setVoiceScriptsPage("")
        setVoiceScriptsError("")
        setVoiceScriptsLoading(true)

        const clientParam = `?client-id=${clientID}`
        const pageParam = page === "" ? "" : `&page=${page}`

        const url = `${apiURL}${clientParam}${pageParam}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as VoiceScriptsResponse;
                
                if (response.status === "error") {
                    setVoiceScriptsError(response.errorMessage)
                } else {
                    if (response.voiceScripts) {
                        setVoiceScripts(response.voiceScripts);
                        setVoiceScriptsPage(response.page);
                    } 
                }
            })
            .catch((error) => setVoiceScriptsError(error.message || "Error fetching voiceScripts"))
            .finally(() => setVoiceScriptsLoading(false))

    }, [refreshToken, page, clientID]);

    return { voiceScripts, voiceScriptsPage, voiceScriptsError, voiceScriptsLoading};
};
